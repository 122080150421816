<template>
    <div>
        <h1 class="page__title">Редактирование {{ pageTitle }}</h1>

        <div class="intro-y box p-5 mt-5">
            <form method="post" class="grid grid-cols-12 gap-6" :class="{ 'disabled-form': loading }">
                <div v-if="loading" class="absolute z-100 left-0 top-0 w-full h-full flex items-center justify-center">
                    <Preloader />
                </div>

                <div class="col-span-6">
                    <label for="form-name" class="form-label">Название <sup class="text-theme-6">*</sup></label>
                    <input
                        id="form-name"
                        v-model="form.title"
                        type="text"
                        class="form-control w-full"
                        placeholder=""
                        autocomplete="off"
                        required
                    />
                </div>

                <div class="col-span-6">
                    <label for="form-slug" class="form-label">Код</label>
                    <input id="form-slug" v-model="form.code" type="text" class="form-control w-full" />
                </div>

                <div class="col-span-12">
                    <div class="wrap-btn-create flex items-center justify-between">
                        <div class="form-label">Слайды</div>
                        <button type="button" class="btn btn-primary" @click="createBannerItem()">
                            <PlusCircleIcon class="stroke-current" />
                        </button>
                    </div>
                    <ContentTable
                        :headers="tableExample"
                        :table-rows="getItemsSort"
                        :table-actions="tableActions"
                        @edit="editAction"
                        @published="publishedAction"
                        @delete="deleteAction"
                    />
                </div>

                <div class="col-span-12 flex items-center justify-end mt-5">
                    <router-link to="#" class="btn btn-secondary w-24 mr-2 mb-2" @click.prevent="redirectToMainPage"
                        >Отмена
                    </router-link>
                    <button type="submit" class="btn btn-primary mb-2 mr-2" @click.prevent="save(false)">
                        Сохранить и выйти
                    </button>
                    <button type="submit" class="btn btn-primary mb-2" @click.prevent="save(true)">Сохранить</button>
                </div>
            </form>
        </div>

        <ModalContainer :modal-open="modalOpen" @closeModal="closeModal">
            <template #header>
                <h2 v-if="item">Редактирование {{ item.title }}</h2>
                <h2 v-else>Создать</h2>
            </template>
            <template #body>
                <form-edit v-if="item" :item="item" @save="itemPush"></form-edit>
                <form-create v-else @save="itemPush"></form-create>
            </template>
        </ModalContainer>

        <PopupDeleteModal
            :is-open="modalDeleteOpen"
            :entity-name="modalDeleteCaption"
            :modal-data="modalDeleteRow"
            @update:isOpen="modalDeleteOpen = $event"
            @deleteModalAccepted="onDeleteModalAccepted"
        />
    </div>
</template>

<script>
import { errorResponse, formPreparation } from '@/mixins/form/form-mixin';
import Preloader from '@/components/preloader/Main';
import ContentTable from '@/components/tables/ContentTable';
import ModalContainer from '@/components/modals/ModalContainer';
import FormCreate from './banner-item-forms/Create';
import FormEdit from './banner-item-forms/Edit';
import PopupDeleteModal from '@/components/modals/PopupDeleteModal';
import { pageMixin } from '@/mixins/pageMixin';

export default {
    name: 'BannerCreate',
    components: { Preloader, ContentTable, ModalContainer, FormCreate, FormEdit, PopupDeleteModal },
    mixins: [formPreparation, errorResponse, pageMixin],
    data() {
        return {
            loading: false,
            form: {
                title: null,
                code: null,
                items: [],
                delete_items: [],
            },
            tableExample: [
                { field: 'index', label: 'Порядковый номер' },
                { field: 'title', label: 'Название' },
            ],
            tableActions: [
                { name: 'edit', title: 'редактировать', icon: 'Edit2Icon', cbName: 'edit' },
                {
                    name: 'published',
                    title: 'опубликовать',
                    class: 'bg-primary-10',
                    icon: 'toggle',
                    cbName: 'published',
                },
                { name: 'delete', title: 'удалить', class: 'bg-red-400', icon: 'Trash2Icon', cbName: 'delete' },
            ],
            pageTitle: null,
            modalOpen: false,
            item: null,
            itemIndex: null,
            deleteModal: false,
            modalDeleteOpen: false,
            modalDeleteCaption: null,
            modalDeleteRow: null,
        };
    },
    computed: {
        getItemsSort() {
            return this.form.items.sort(function (a, b) {
                return a.index - b.index;
            });
        },
    },
    async created() {
        await this.fetchData();
    },
    methods: {
        async fetchData() {
            this.loading = true;

            await this.axios
                .get(`/banners/${this.$route.params.id}/edit`)
                .then((res) => {
                    this.pageTitle = res.data.data.title;

                    for (const [key, val] of Object.entries(res.data.data)) {
                        this.form[key] = val;
                    }
                    this.loading = false;
                })
                .catch((ex) => {
                    this.loading = false;
                    console.log('cant fetch content page categories: ' + ex);
                });
        },
        closeModal() {
            this.modalOpen = false;
            this.item = null;
            this.itemIndex = null;
        },
        createBannerItem() {
            this.modalOpen = true;
        },
        editAction(row, index) {
            this.item = row;
            this.itemIndex = index;
            this.modalOpen = true;
        },
        async publishedAction(row) {
            this.loading = true;

            try {
                const publishedVal = !row.published;
                const published = publishedVal ? 1 : 0;
                await this.axios.put(`/banners/toggle-published-item/${row.id}`, { published });
                await this.fetchData(this.$route.query);
                this.loading = false;
            } catch (ex) {
                this.loading = false;
                console.log('cant toggle publish: ' + ex);
            }
        },
        deleteAction(row) {
            this.modalDeleteOpen = true;
            this.modalDeleteCaption = 'баннер';
            this.modalDeleteRow = row;
            this.item = row;
            this.itemIndex = this.form.items.indexOf(row);
        },
        onDeleteModalAccepted() {
            if (this.item.id) {
                this.form.delete_items.push(this.item.id);
            }
            this.form.items.splice(this.itemIndex, 1);
            this.item = null;
            this.itemIndex = null;
            this.deleteModal = false;
        },
        itemPush(item) {
            console.log('itemPush ~ item:', item);
            if (this.item) {
                this.form.items[this.itemIndex] = item;
            } else {
                this.form.items.push(item);
            }
            this.form.items.sort();
            console.log('itemPush ~ this.form.items:', this.form.items);
            this.closeModal();
        },
        async save(flag) {
            this.loading = true;

            const form = this.formPreparation({ imagesAsObject: true });
            await this.axios
                .post('/banners/' + this.$route.params.id, form, {
                    params: {
                        _method: 'patch',
                    },
                })
                .then((res) => {
                    this.fetchData();
                    this.form.delete_items = [];
                    this.loading = false;
                    if (flag) {
                        this.$notify('Запись сохранена');
                    } else {
                        // this.$router.push({ name: 'content-banners' });
                        this.redirectToMainPage();
                    }
                })
                .catch((ex) => {
                    this.errorResponse(ex);
                });
        },
        redirectToMainPage() {
            this._pMTransitionToEditPageWithQuery('content-banners');
        },
    },
};
</script>

<style></style>
